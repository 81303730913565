import React from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import Welcome from './components/Welcome/Welcome';
import OurAdventure from './components/OurAdventure/OurAdventure';
import Lodging from './components/Lodging/Lodging';
import Location from './components/Location/Location';
import Timeline from './components/Timeline/Timeline';
import Attire from './components/Attire/Attire';
import RSVP from './components/RSVP/RSVP';
import Registry from './components/Registry/Registry';
import Submissions from './components/Submissions/Submissions';
import Wenatchee from './components/Wenatchee/Wenatchee';

function App() {
  // when in prod, don't be outputting to console.
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <HashRouter>
      <Routes>
        <Route exact path="/welcome" element={<Welcome />} />
        <Route exact path="/our-adventure" element={<OurAdventure />} />
        <Route exact path="/links" element={<Lodging />} />
        <Route exact path="/lodging" element={<Lodging />} />
        <Route exact path="/location" element={<Location />} />
        <Route exact path="/timeline" element={<Timeline />} />
        <Route exact path="/attire" element={<Attire />} />
        <Route exact path="/rsvp" element={<RSVP />} />
        <Route exact path="/registry" element={<Registry />} />
        <Route exact path="/submissions" element={<Submissions />} />
        <Route exact path="/wenatchee" element={<Wenatchee />} />

        <Route path="*" element={<Navigate to="/welcome" replace />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
