import React from 'react';

import styles from './Lodging.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Lodging() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1> Hotels Near By!</h1>
        <p>
          There are three local hotels within a five to seven-mile radius of the
          venue.
        </p>
        <ForwardLink href=" https://archerhotel.com/redmond">
          {' '}
          Archer Hotel
        </ForwardLink>
        <ForwardLink href=" https://www.marriott.com/en-us/hotels/seamc-seattle-marriott-redmond/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0">
          {' '}
          Marriot
        </ForwardLink>
        <ForwardLink href=" https://www.hilton.com/en/hotels/searehx-hampton-suites-seattle-redmond/">
          {'Hampton'}
        </ForwardLink>
        <p> AirBnb options nearby: </p>
        <ForwardLink href="https://www.airbnb.com/s/Redmond--WA/homes?adults=1&place_id=ChIJI-4AIK0MkFQR8gTYxeqQA14&refinement_paths%5B%5D=%2Fhomes&property_type_id%5B%5D=2&room_types%5B%5D=Entire%20home%2Fapt&checkin=2024-08-23&checkout=2024-08-25&tab_id=home_tab&query=Redmond%2C%20WA&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-04-01&monthly_length=3&monthly_end_date=2024-07-01&price_filter_input_type=0&price_filter_num_nights=2&channel=EXPLORE&ne_lat=47.7331276315726&ne_lng=-121.92826062253363&sw_lat=47.582113110892884&sw_lng=-122.10278672684751&zoom=11.767910665233444&zoom_level=11.767910665233444&search_by_map=true&search_type=user_map_move">
          {' '}
          Airbnb search of the area
        </ForwardLink>
        <p>
          There are some select camping sites. Here is one you can reserve ~18
          min away:
        </p>
        <ForwardLink href="https://kingcounty.gov/en/dept/dnrp/nature-recreation/parks-recreation/king-county-parks/rentals/camping">
          Tolt McDonald Park
        </ForwardLink>
        {/* <p href="https://www.airbnb.com/rooms/840653477950340746?source_impression_id=p3_1710866582_CMLZZ6R9VCybvbrn">
          {'An AirBnB'}
        </p> */}
        <h1> The Venue:</h1>
        <ForwardLink href="https://www.emeraldforestredmond.com/">
          {' '}
          Emerald Forest Website!{' '}
        </ForwardLink>
        <img className={styles.treeHousePhoto} src="emerald-forest.jpeg"></img>
      </div>
    </>
  );
}

export default Lodging;
