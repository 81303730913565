import React from 'react';

import styles from './Wenatchee.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Wenatchee() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1>Wenatchee Recommendations</h1>

        <h2>Restaurants:</h2>
        <ul className={styles.list}>
          <li>
            <div>
              <ForwardLink href="">Saddle Rock</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcc94424fe373:0xfdd6f5f4bf0c52e?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">
                Atlas Fare (Reservation Recommended)
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcd7b49b2965f:0x660f9196be0c462f?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">McGlins</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcc9529553217:0xc6770906ccf2956?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">The Taproom</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcd2489353cad:0xaf187d3e94ef4020?hl=en"
              ></ForwardLink>
            </div>
          </li>
        </ul>

        <h2>Wine:</h2>
        <ul className={styles.list}>
          <li>
            <div>
              <ForwardLink href="https://www.watermillwinery.com/watermill-on-the-ave/">
                Watermill
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcd0ee19e643d:0xacaa5a0f7c792e0c?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">Norwood</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcc94c243f3f5:0xc2843d5d821ce917?hl=en"
              ></ForwardLink>
            </div>
          </li>
        </ul>

        <h2>Bars:</h2>
        <ul className={styles.list}>
          <li>
            <div>
              <ForwardLink href="">Ground Control</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcddb65e83be1:0xa499ad5a8bd73564?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">Water Core</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcd567a8571e9:0x7e258fee0a17bc99?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">Union Hill</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bcd43e0d09803:0xde0873179eacd2f5?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="">Columbia Valley Brewing</ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bb52c7c70746b:0xbae4a610f02fd172?hl=en"
              ></ForwardLink>
            </div>
          </li>
        </ul>

        <h2>Bakeries:</h2>
        <ul className={styles.list}>
          <li>
            <div>
              <ForwardLink href="https://www.anjoubakery.com/">
                Anjou
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bb54f442815a1:0x6bd88dffe49d5875?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="https://m.facebook.com/LaVieEncoffeebarbakery">
                La Vien
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x549bb54f442815a1:0x6bd88dffe49d5875?hl=en"
              ></ForwardLink>
            </div>
          </li>
        </ul>

        <h2>Activities:</h2>
        <ul className={styles.list}>
          <li>
            <div>
              <ForwardLink href="https://leavenworth.org/">
                Visit Leavenworth
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place/Leavenworth+Square/@47.5955563,-120.7443422,12z/data=!4m10!1m2!2m1!1sleavenworth+main+street+map!3m6!1s0x549a4d8b61bfaa53:0x4929a3e519ecc2f6!8m2!3d47.5955858!4d-120.6619417!15sChtsZWF2ZW53b3J0aCBtYWluIHN0cmVldCBtYXBaGSIXbGVhdmVud29ydGggbWFpbiBzdHJlZXSSAQRwYXJrmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVF4ZWxsMWRYaDNSUkFC4AEA!16s%2Fg%2F11b6v3h9cj?entry=ttu"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="https://www.ohmegardens.org/thegardens/photo-gallery">
                Ohme Gardens
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place/Ohme+Gardens/data=!4m2!3m1!1s0x549bcbade4514053:0x6403fdeaaf1f580?hl=en"
              ></ForwardLink>
            </div>
          </li>
          <li>
            <div>
              <ForwardLink href="https://www.wta.org/go-hiking/hikes/saddle-rock">
                Hike Saddle Rock
              </ForwardLink>
              <ForwardLink
                pin={true}
                href="https://www.google.com/maps/place/Saddle+Rock+Trailhead/@47.3982975,-120.3411567,15z/data=!4m14!1m7!3m6!1s0x549a332a25423da5:0x962bcf07c4aa1e5e!2sSaddle+Rock+Trailhead!8m2!3d47.3970339!4d-120.3298378!16s%2Fg%2F11bzv2t8w5!3m5!1s0x549a332a25423da5:0x962bcf07c4aa1e5e!8m2!3d47.3970339!4d-120.3298378!16s%2Fg%2F11bzv2t8w5?entry=ttu"
              ></ForwardLink>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Wenatchee;
