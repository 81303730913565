import React from 'react';

import styles from './RSVP.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import useWindowDimensions from '../SlideShowPhotos/SlideShowPhotos.jsx';

function RSVP() {
  const { width } = useWindowDimensions();

  const widthAmount = width < 500 ? 'auto' : '640';

  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeox1RWJ91hnUNrzfLja5_1mC0hn9ybkWGyguH4Z58Z4eJRXA/viewform?embedded=true"
          width={widthAmount}
          height="633"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
}

export default RSVP;
