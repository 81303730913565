import React from 'react';

import styles from './Submissions.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Submissions() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1> Contribute to our Wedding Experience!</h1>
        <ForwardLink
          href="https://drive.google.com/drive/folders/19Ek5ptRze5ccvh0eNJUBvvZ4WM6prNgM?usp=sharing"
          rel="noreferrer noopener"
          target="_blank"
        >
          {' '}
          Add Submissions for Pictures/Memories with us
        </ForwardLink>
        <p>So we can throw them up on the projector during our wedding</p>
        <h3> Song Request options to come!</h3>
      </div>
    </>
  );
}

export default Submissions;
