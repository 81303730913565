import React from 'react';

import styles from './Timeline.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Timeline() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1> Wedding Program for August 24th 2024</h1>
        <ul className={styles.list}>
          <li>5:00 PM&nbsp; Arrival</li>
          <li>
            5:30 PM&nbsp;{' '}
            <ForwardLink href="https://www.youtube.com/watch?v=qvrNwGVCl2I">
              Ceremony
            </ForwardLink>{' '}
          </li>
          <li> 6:30 PM&nbsp; Aperitivo - Open Seating</li>
          <ul>
            <li>Wine: White, Rose, and Red </li>
            <li>Assortment of Beer</li>
          </ul>
          <li>
            {' '}
            7:00 PM&nbsp; Dinner - Open Seating
            <ul>
              Appetizer:
              <ul>
                <li>Arugula Salad with orange, pair, walnuts, vinaigrette </li>
                <li>Rolls with whipped herb butter</li>
              </ul>
              Main Dish Pairs Together:
              <ul>
                <li>Tenderloin with Cognac Caramelized Shallot Demi</li>
                <li>Wild Salmon with hickory smoked sea-salt</li>
              </ul>
            </ul>
          </li>
          <li> 7:45 PM&nbsp; Toasts</li>
          <li> 8:00 PM&nbsp; Dance</li>
          <li>
            9:15 PM&nbsp; Venue Surprise!&nbsp;&nbsp; We don&apos;t know what it
            is either!
          </li>
          <li> 9:45 PM&nbsp; Zip-line Sendoff</li>
          <li>10:00 PM&nbsp; Guests depart</li>
        </ul>
      </div>
    </>
  );
}

export default Timeline;
