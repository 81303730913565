import React from 'react';

import styles from './Registry.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Registry() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1>Our Registries!</h1>
        <ul>
          <li className={styles.regLink}>
            <ForwardLink href="https://www.amazon.com/wedding/registry/Y6NG9CCPCAW0">
              Amazon
            </ForwardLink>
          </li>
          <li className={styles.regLink}>
            <ForwardLink href="https://www.anthropologie.com/registry/listing?registryId=4F52A7565637">
              Anthropologie
            </ForwardLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Registry;
