import React from 'react';

import styles from './Location.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Location() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1> Our Venue is the Emerald Forest in Redmond!</h1>
        <ForwardLink href="https://www.emeraldforestredmond.com/">
          {' '}
          Emerald Forest Website!{' '}
        </ForwardLink>
        <p>
          {' '}
          Parking on the side of the road is small and can only fit ~10-12 cars.
          Plan to carpool. We are going to get an AirBnb nearby that may serve
          for carpool parking. The trailhead to the venue is hard to find so I
          have provided a wonderful photo guide below along with a google maps
          pin. It is a 10 min walk over uneven ground to get to the venue.
        </p>
        <div className={styles.mapContainer}>
          <div className={styles.directions}>
            <img className={styles.emeraldMap} src="hidden-trail.jpg"></img>
            <img className={styles.emeraldMap} src="trail-2.jpg"></img>
          </div>
          <div className={styles.maps}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5275.102632202969!2d-122.01791384484268!3d47.67423465250653!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDQwJzMwLjEiTiAxMjLCsDAxJzAzLjUiVw!5e1!3m2!1sen!2sus!4v1714925617526!5m2!1sen!2sus"
              width="600"
              height="450"
              className={styles.googleMap}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <img className={styles.emeraldMap} src="map-emerald.jpg"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default Location;
