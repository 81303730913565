import styles from './Header.module.css';
import { NavLink } from 'react-router-dom';
import React from 'react';

function Tabs() {
  return (
    <div className={styles.flexboxContainer}>
      <nav className={styles.tabs}>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/location"
        >
          Location
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/our-adventure"
        >
          Our Adventure
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/timeline"
        >
          Timeline
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/lodging"
        >
          Lodging
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/attire"
        >
          Attire
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/rsvp"
        >
          RSVP
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/registry"
        >
          Registry
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/submissions"
        >
          Submissions
        </NavLink>
        <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/wenatchee"
        >
          Wenatchee
        </NavLink>

        {/* <NavLink
          className={(isActive) =>
            isActive.isActive ? styles.activeTab : styles.tab
          }
          to="/"
        >
          Welcome!
        </NavLink> */}
      </nav>
    </div>
  );
}

export default Tabs;
