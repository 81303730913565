import React from 'react';

import { SlideshowPhotos } from '../SlideShowPhotos/SlideShowPhotos';
import styles from './OurAdventure.module.css';
import Tabs from '../Tabs/Tabs.jsx';

function OurAdventure() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1 className={styles.OurAdventure}> Our Adventure!</h1>
        <p className={styles.ptag}>
          We have a rich history of going out into nature and adventuring!{' '}
        </p>
        <p className={styles.ptag}>
          From our early trips exploring moab and zions
        </p>
        <p className={styles.ptag}>
          to experiencing Adrienne&apos;s first backpacking trip together in
          Escalante
        </p>
        <p className={styles.ptag}>
          We have shared rock climbing in the west Deserts
        </p>
        <p className={styles.ptag}>
          To ascending the highest Peak of Utah in the Uintas!{' '}
        </p>
        <p className={styles.ptag}>
          We&apos;re excited to have you join us in Nature to Celebrate our
          Bond!
        </p>
      </div>
      <div className={styles.photos}>
        <SlideshowPhotos />
      </div>
    </>
  );
}

export default OurAdventure;
