import React from 'react';
import PropTypes from 'prop-types';
import styles from './ForwardLink.module.css';

function ForwardLink({ href, children, pin }) {
  return (
    <a
      className={styles.link}
      rel="noreferrer noopener"
      target="_blank"
      href={href}
    >
      {pin ? (
        <>
          <img className={styles.mapIcon} src="maps-icon.png"></img>
        </>
      ) : (
        children
      )}
    </a>
  );
}
ForwardLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pin: PropTypes.bool,
};

export default ForwardLink;
