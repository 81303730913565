import styles from './Welcome.module.css';
import { NavLink } from 'react-router-dom';
import React from 'react';
import useWindowDimensions from '../../helper/window-size';

function Welcome() {
  const { height, width } = useWindowDimensions();

  return (
    <div className={styles.header}>
      {/* <div className={styles.flexboxContainer}> */}
      {width > 496 ? (
        <img
          className={styles.myFace}
          src="proposal pic.JPG"
          alt="A picture of the proposal "
        />
      ) : (
        <img
          className={styles.myFace}
          src="proposal pic crop.JPG"
          alt="a glacier in enchantments"
        />
      )}
      <div className={styles.overlap}>
        {/* <div className={styles.flexbox}> */}
        <h1 className={styles.adrienneandnate}>
          Adrienne &#10;&#13; and &#10;&#13; Nate
          <h2 className={styles.title}> Aug 24th!</h2>
        </h1>

        <nav className={styles.tabs}>
          <NavLink
            className={(isActive) =>
              isActive.isActive ? styles.activeTab : styles.tab
            }
            to="/location"
          >
            Location
          </NavLink>
          <NavLink
            className={(isActive) =>
              isActive.isActive ? styles.activeTab : styles.tab
            }
            to="/our-adventure"
          >
            Our Adventure
          </NavLink>
          <NavLink
            className={(isActive) =>
              isActive.isActive ? styles.activeTab : styles.tab
            }
            to="/timeline"
          >
            Timeline
          </NavLink>
          <NavLink
            className={(isActive) =>
              isActive.isActive ? styles.activeTab : styles.tab
            }
            to="/lodging"
          >
            Lodging
          </NavLink>
        </nav>
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}

export default Welcome;
