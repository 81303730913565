import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styles from './SlideShowPhotos.module.css';
const fadeImages = [
  {
    url: 'japan-volcano.JPG',
  },
  {
    url: 'inTheRain.jpg',
  },
  {
    url: 'rain.jpeg',
  },
  {
    url: 'moab.JPG',
  },
  // {
  // url: 'aj-coyote-gulche.JPG',
  // },
  {
    url: 'coyote-together.JPG',
  },
  {
    url: 'mini-grand-canyon.jpeg',
  },
  {
    url: 'kimonos.JPG',
  },

  {
    url: 'idaho.jpeg',
  },
  {
    url: 'enchantments.jpeg',
  },
];

import { useState, useEffect } from 'react';

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const SlideshowPhotos = () => {
  const { height, width } = useWindowDimensions();

  const stylin =
    width < 500
      ? {
          display: 'flex',
          justifyContent: 'center',
          width: '100vw',
          marginLeft: 'auto',
          marginRight: 'auto',
        }
      : {
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          marginLeft: 'auto',
          marginRight: 'auto',
        };
  return (
    <div className="slide-container">
      <Fade>
        {fadeImages.map((fadeImage, index) => (
          <div key={index}>
            <img className={styles.image} style={stylin} src={fadeImage.url} />
            <h2>{fadeImage.caption}</h2>
          </div>
        ))}
      </Fade>
    </div>
  );
};
