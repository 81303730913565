import React from 'react';

import styles from './Attire.module.css';
import Tabs from '../Tabs/Tabs.jsx';
import ForwardLink from '../Common/ForwardLink.jsx';

function Attire() {
  return (
    <>
      <Tabs />
      <div className={styles.container}>
        <h1>You will be in a Forest!</h1>
        <p>
          {' '}
          You will be walking on a forest floor. Do not wear shoes you would be
          afraid to get dirty, or that would be uncomfortable to wear for the
          extended time.
        </p>
        <p>The 10 min walk in is uneven with roots and board walks.</p>
        <h2>Womens Dress:</h2>
        <p>
          Semi Formal with Bohemian vibes. Shoes: wedges, chunky heels, or
          flats.
        </p>
        <h2>Mens Dress:</h2>
        <p>
          Semi Formal, tie to match dark orange or teal (see links below).
          Otherwise wear neutral colors.
        </p>
        <ForwardLink href="https://www.amazon.com/dp/B07DVYFRYY?psc=1&ref=ppx_yo2ov_dt_b_product_details">
          Orange tie link
        </ForwardLink>
        <ForwardLink href="https://www.amazon.com/dp/B0969W8HSH?psc=1&ref=ppx_yo2ov_dt_b_product_details">
          Teal tie link
        </ForwardLink>
        <h1>Color Theme:</h1>
        <img className={styles.colors} src="colors.jpeg"></img>
      </div>
    </>
  );
}

export default Attire;
